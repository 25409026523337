var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm.view === _vm.ORDER_VIEW.MAIN_ORDER
        ? _c(
            "div",
            { staticClass: "div-main-list-page" },
            [
              _c("div", { staticClass: "div-main-list-page-title" }, [
                _c("h1", [
                  _vm._v(
                    _vm._s(
                      _vm.orderType === _vm.ORDER_TYPE.DELIVERED
                        ? "Delivered"
                        : _vm.orderType === _vm.ORDER_TYPE.EX_WHARF
                        ? "Ex-Wharf"
                        : "Barging Nomination"
                    )
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "div-main-list-page-tab-search-btn",
                  class: {
                    "flex-left-column": _vm.isMobileScreen,
                    "flex-between-row": !_vm.isMobileScreen,
                  },
                },
                [
                  _c(
                    "el-tabs",
                    {
                      staticClass: "tab-bar",
                      on: {
                        "tab-click": function ($event) {
                          return _vm.handleGetOrders(
                            _vm.orderPagination.pageSize,
                            0
                          )
                        },
                      },
                      model: {
                        value: _vm.orderTabActive,
                        callback: function ($$v) {
                          _vm.orderTabActive = $$v
                        },
                        expression: "orderTabActive",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "All", name: "All" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "Draft", name: "Draft" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "Open Enquiry", name: "Open Enquiry" },
                      }),
                      _c("el-tab-pane", {
                        attrs: {
                          label: "Pending Credit Authorization",
                          name: "Pending Credit Authorization",
                        },
                      }),
                      _c("el-tab-pane", {
                        attrs: {
                          label: "Credit Authorization Rejected",
                          name: "Credit Authorization Rejected",
                        },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "Confirmed", name: "Confirmed" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "Verified", name: "Verified" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "Archive", name: "Archive" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex-end" }, [
                    _c(
                      "div",
                      { staticClass: "search-bar" },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "round-input",
                            attrs: {
                              size: _vm.isMobileScreen ? "mini" : "small",
                              placeholder: `Search Order No. ${
                                _vm.orderType === _vm.ORDER_TYPE.EX_WHARF
                                  ? "or Terminal"
                                  : "or Vessel"
                              }`,
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleGetOrders(
                                  _vm.orderPagination.pageSize,
                                  0,
                                  true
                                )
                              },
                            },
                            model: {
                              value: _vm.orderSearch,
                              callback: function ($$v) {
                                _vm.orderSearch = $$v
                              },
                              expression: "orderSearch",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                size: _vm.isMobileScreen ? "mini" : "small",
                                type: "primary",
                                icon: "el-icon-search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleGetOrders(
                                    _vm.orderPagination.pageSize,
                                    0,
                                    true
                                  )
                                },
                              },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                      ? _c(
                          "div",
                          { staticStyle: { "padding-top": "5px" } },
                          [
                            _c(
                              "el-dropdown",
                              {
                                staticClass: "primary-button",
                                attrs: { trigger: "click" },
                                on: { command: _vm.handleCreateOrderSelect },
                              },
                              [
                                _c("span", [
                                  _vm._v(" Create Order"),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-down el-icon--right",
                                  }),
                                ]),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          command:
                                            _vm.ORDER_TRADE_TYPE.PURCHASE,
                                        },
                                      },
                                      [_vm._v("Purchase Order")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          command: _vm.ORDER_TRADE_TYPE.SALES,
                                        },
                                      },
                                      [_vm._v("Sales Order")]
                                    ),
                                    _vm.orderType !== _vm.ORDER_TYPE.EX_WHARF
                                      ? _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              command:
                                                _vm.ORDER_TRADE_TYPE
                                                  .PURCHASE_AND_SALES,
                                            },
                                          },
                                          [_vm._v("Purchase & Sales Order")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("table-order", {
                attrs: {
                  hasPagination: "",
                  hasTableMenu: "",
                  isOrderTableLoading: _vm.isOrderTableLoading,
                  orderTableData: _vm.orderTableData,
                  orderPagination: _vm.orderPagination,
                  isAllVerified:
                    _vm.orderTabActive === "Verified" || _vm.isAllVerified,
                  orderType: _vm.orderType,
                },
                on: {
                  handleOrderTableMenuClick: _vm.handleOrderTableMenuClick,
                  handleOrderPageChange: _vm.handleOrderPageChange,
                  handleOrderPageSizeChange: _vm.handleOrderPageSizeChange,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.view !== _vm.ORDER_VIEW.MAIN_ORDER
        ? _c("order-form", {
            attrs: {
              propsView: _vm.view,
              orderType: _vm.orderType,
              propsTradeType: _vm.tradeType,
              orderId: _vm.orderId,
              isAllVerified:
                _vm.orderTabActive === "Verified" || _vm.isAllVerified,
              isOrderSideBar: "",
            },
            on: {
              handleCloseOrderForm: _vm.handleCloseOrderForm,
              handleOrderViewChange: _vm.handleOrderViewChange,
              handleOrderIdChange: _vm.handleOrderIdChange,
              handleIsAllVerifiedChange: _vm.handleIsAllVerifiedChange,
            },
          })
        : _vm._e(),
      _c("dialog-link-order", {
        attrs: {
          isUnlink: _vm.isUnlink,
          orderType: _vm.ORDER_TYPE.DELIVERED,
          order: _vm.orderSelected,
          dialogLinkOrder: _vm.dialogLinkOrder,
        },
        on: {
          handleLinkOrderDialogClose: function ($event) {
            _vm.dialogLinkOrder = false
          },
          handleConfirmLinkOrderClick: _vm.handleConfirmLinkOrderClick,
        },
      }),
      _c("dialog-link-jobs", {
        attrs: {
          isUnlink: _vm.isUnlink,
          dialogLinkJobs: _vm.dialogLinkJobs,
          orderId: _vm.orderId,
          order: _vm.orderSelected,
        },
        on: {
          handleLinkJobsDialogClose: function ($event) {
            _vm.dialogLinkJobs = false
          },
        },
      }),
      _c("dialog-attachment", {
        attrs: {
          order: _vm.orderSelected,
          dialogAttachment: _vm.dialogAttachment,
        },
        on: {
          handleAttachmentDialogClose: function ($event) {
            _vm.dialogAttachment = false
          },
        },
      }),
      _vm.blockchainInfoVisible
        ? _c("dialog-blockchain-info", {
            attrs: { propsData: _vm.blockchainData },
            on: { onClose: _vm.handleCloseBlockchainInfo },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }